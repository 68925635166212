import { Injectable, effect, inject } from "@angular/core";
import { ContextService } from "@smallstack/client-common";

@Injectable({ providedIn: "root" })
export class ResellerContextService extends ContextService {
  private contextService = inject(ContextService);

  constructor() {
    super();
    effect(
      () => {
        const globalContext = this.contextService.context();
        this.setContext({
          tenantId: globalContext.resellerId,
          resellerId: globalContext.resellerId,
          authTenantId: globalContext.resellerId,
          userId: globalContext.userId,
          token: globalContext.token
        });
      },
      { allowSignalWrites: true }
    );
  }
}
